import authService from '../../auth/authService'
import * as Sentry from '@sentry/vue'
import Vue from 'vue'

const userDefaults = {
  uid: 0,
  displayName: '',
  about: '',
  photoURL: require('@/assets/images/portrait/small/avatar-s-11.jpg'), // From Auth
  status: 'online',
  userRole: '/'
}

export default {
  namespaced: true,
  state: {
    AppActiveUser: userDefaults,
    loggedIn: false,
    userData: null,
    userHasClients: true,
    userPermissions: [],
    selected_client: {},
    allowedClients: {}
  },
  getters: {
    userData: state => {
      return state.userData
    },
    loggedIn: state => {
      return state.loggedIn
    },
    selected_client: state => {
      return state.selected_client
    },
    allowedClients: state => {
      return state.allowedClients
    },
    userPermissions: state => {
      return state.userPermissions
    }
  },
  mutations: {
    SET_LOGGED_IN(state) {
      state.loggedIn = true
    },
    async UPDATE_USER_DATA(state, data) {
      state.userData = data

      if (data && data.id) {
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: data.id,
            username: data.name,
            email: data.email
          })

          scope.setTag('client_name', data.client_name)
          scope.setTag('tax_number', data.tax_number)
          scope.setTag('client_id', data.client_id)
        })

      }
    },
    SET_LOGIN_STATUS(state, status) {
      state.loggedIn = status
    },
    SET_SELECTED_CLIENT(state, status) {
      state.selected_client = status
    },
    async SET_USER_NO_CLIENTS(state, status) {

      const router = (await import('@/router')).default

      if (!status && router.currentRoute.path !== '/no-clients') {
          await router.push('no-clients')
      } else if (status && router.currentRoute.path === '/no-clients') {
        await router.push('/')
      }

     state.userHasClients = status
    },
    async SET_ASSIGNED_CLIENTS(state) {
      try {
        const response =  await Vue.prototype.$http.get('/api/client/v1/assigned_clients')
        state.allowedClients = response.data
      } catch (err) {
        Sentry.captureException(err)
      }
    },
    async SELECT_FIRST_CLIENT(state) {

      const response1 =  await Vue.prototype.$http.get('/api/client/v1/assigned_clients')
      state.allowedClients = response1.data

      if (!state.allowedClients || state.allowedClients.length <= 0) {
        this.commit('user/SET_USER_NO_CLIENTS', false)
        return
      }

      this.selected_client = state.allowedClients[0].id

      const response = await Vue.prototype.$http.get(`/api/client/v1/change_client/${this.selected_client}`)


      const res = await Vue.prototype.$http.get('/api/client/v1/users/permissions')
      const permissions = res.data ?? []

      const res1 = await Vue.prototype.$http.get('/api/client/v1/client/data')
      const clientData = res1.data ?? {}

      state.userPermissions = permissions
      this.commit('client/SET_CLIENT_DATA', clientData)

      this.commit('user/UPDATE_USER_DATA', response.data)
    },
    async SET_USER_PERMISSIONS(state, data) {
      state.userPermissions = data
    }
  },
  actions: {
    login({ commit }, payload) {
      authService.setLoginParameters(payload)
      commit('SET_LOGGED_IN')
    },
    setUserData({ commit }, payload) {
      commit('UPDATE_USER_DATA', payload)
      localStorage.setItem('userData', JSON.stringify(payload))
    },
    setLoginStatus({commit}, data) {
      commit('SET_LOGIN_STATUS', data)
    },
    updateUserData({commit}, data) {
      commit('UPDATE_USER_DATA', data)
    },
    logout({dispatch, state}) {
      if (state.loggedIn) {
        Vue.prototype.$http.get('/api/client/v1/logout/').then()
      }

      localStorage.removeItem('userData')
      dispatch('setLoginStatus', false)
      dispatch('updateUserData', {})
    },
    setSelectedClient({commit}, data) {
      commit('SET_SELECTED_CLIENT', data)
    },
    setUserNoClients({commit}, data) {
      commit('SET_USER_NO_CLIENTS', data)
    },
    selectFirstClient({commit}, data) {
      commit('SELECT_FIRST_CLIENT', data)
    },
    setAssignedClients({commit}, data) {
      commit('SET_ASSIGNED_CLIENTS', data)
    },
    setUserPermissions({commit}, data) {
      commit('SET_USER_PERMISSIONS', data)
    }
  }
}
