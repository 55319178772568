import i18n from '@/i18n/i18n'

export default {
    namespaced: true,
    state: {
        piece_types:[{name: 'Kos', type:'piece', factor:100, defaultConverted: 100}],
        weight_types: [
            {name:'g', type:'weight', factor:1, defaultConverted: 1},
            {name:'dag', type:'weight', factor:10, defaultConverted: 1},
            {name:'kg', type:'weight', factor:1000, defaultConverted: 1}
        ],
        volume_types: [
            {name:'mL', type:'volume', factor:1, defaultConverted: 1},
            {name:'dL', type:'volume', factor:100, defaultConverted: 1},
            {name:'L', type:'volume', factor:1000, defaultConverted: 1}
        ],
        money_types: [{ name: 'EUR', type: 'money', factor: 1000000, defaultConverted: 1000000}],
        amount_types: [
            {name: i18n.t('general.piece'), id: 'piece'},
            {name: i18n.t('general.volume'), id: 'volume'},
            {name: i18n.t('general.weight'), id: 'weight'},
            {name: i18n.t('general.money'), id: 'money'}
        ]
    },
    getters: {
        moneyTypes: state => {
            return state.money_types
        },
        pieceTypes: state => {
            return state.piece_types
        },
        weightTypes: state => {
            return state.weight_types
        },
        volumeTypes: state => {
            return state.volume_types
        },
        amountTypes: state => {
            return state.amount_types
        }
    },
    mutations: {
    },
    actions: {
    }
}
