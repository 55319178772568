import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'
import setup from './setup'
import priceList from './price-list'
import ingredients from './ingredients'
import messages from './messages'
import employees from './employees'
import client from './client'

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    setup,
    user,
    priceList,
    ingredients,
    messages,
    employees,
    client
  },
  strict: process.env.DEV
})
